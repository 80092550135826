<template>
  <v-container>
    <v-row>
      <div class="d-inline pa-2">
        <h1 id="v-step-0">{{ api.displayName }}</h1>
        <v-skeleton-loader
        v-if="!api.displayName"
        class="mx-auto"
        min-width="500"
        type="heading"
      ></v-skeleton-loader>
      </div>
    </v-row>
    <v-row v-if="api.isOwner">
      <v-col>
        <v-btn :href="'/apis/' + apiId" target="_blank">
          <v-icon left style="color: green">mdi-pencil</v-icon> Edit Api
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <overview-component :api="api" :api-details="apiDetails" :environments="environments" />
      </v-col>
      <v-col cols="12" md="6">
        <subscription-component :products="products" :deployments="deployments" :api-id="apiId" :api-security-policy="apiSecurityPolicy" @subscriptionSuccessful="getSubscribedProducts()"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12">
        <v-tabs
          left
          dark
          v-model="tabView"
        >
          <v-tab id="v-step-6">
            API Console
          </v-tab>
          <v-tab id="v-step-8">
            Subscribed Products
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabView">
          <v-tab-item>
            <api-console-component :deployments="deployments" :subscribed-products="subscribedProducts" :selected-version="selectedVersion" :api-id="apiId" :api="api" :api-security-policy="apiSecurityPolicy" :environments="environments" @updateVersion="setVersion"/>
          </v-tab-item>
          <v-tab-item>
            <subscribed-products-component :api="api" :subscribed-products="subscribedProducts" @unsubscribeSuccessful="getSubscribedProducts()" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'

import overviewComponent from './components/overviewComponent'
import subscriptionComponent from './components/subscriptionComponent'
import apiConsoleComponent from './components/apiConsoleComponent'
import subscribedProductsComponent from './components/subscribedProductsComponent'

export default {
  name: 'ViewApi',
  components: {
    'overview-component': overviewComponent,
    'subscription-component': subscriptionComponent,
    'api-console-component': apiConsoleComponent,
    'subscribed-products-component': subscribedProductsComponent
  },
  data: () => ({
    selectedVersion: null,
    tabView: 0,
    apiId: '',
    products: [ ],
    subscribedProducts: [ ],
    environments: [ ],
    deployments: { },
    api: {
      displayName: null,
      availableVersions: [ ]
    },
    apiDetails: null,
    isLoading: true
  }),
  watch: {
    selectedVersion: function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getApiVersionDetails()
      }
    }
  },
  methods: {
    setVersion: function (version) {
      this.selectedVersion = version
    },
    getProducts: function () {
      axios(
        `${process.env.VUE_APP_BASEURL}/product?showOwnedProductsOnly=true`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.products = response.data.items
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getProducts() })
          })
    },
    getSubscribedProducts: function () {
      axios(
        `${process.env.VUE_APP_BASEURL}/api/${this.apiId}/products`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.subscribedProducts = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getSubscribedProducts() })
          })
    },
    getEnvironments: function () {
      axios(
        `${process.env.VUE_APP_BASEURL}/environments/`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.environments = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getEnvironments() })
          })
    },
    getApiVersionDetails: function () {
      this.isLoading = true
      axios(
        `${process.env.VUE_APP_BASEURL}/api/${this.apiId}/versions/${this.selectedVersion}?publicView=true`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.apiDetails = response.data
            this.isLoading = false
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getApiVersionDetails() })
          })
    },
    getApiInfo: function () {
      return axios(
        `${process.env.VUE_APP_BASEURL}/api/${this.apiId}/meta`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.api = response.data
            this.selectedVersion = this.api.availableVersions ? this.api.availableVersions[this.api.availableVersions.length - 1] : null
            return true
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getApiInfo() })
          })
    },
    getDeployments: function () {
      return axios(
        `${process.env.VUE_APP_BASEURL}/deployments/apis/${this.apiId}/`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.deployments = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getDeployments() })
          })
    }
  },
  computed: {
    isPingJwt: {
      get: function () {
        if (this.apiDetails && this.apiDetails.policies) {
          return this.apiDetails.policies.some(x => {
            return x.policyType === 1 && x.policyName === 'HCA-Ping-JWT-Proxy-Protection-Shared-Flow'
          })
        }
        return false
      }
    },
    isApiKey: {
      get: function () {
        if (this.apiDetails && this.apiDetails.policies) {
          return this.apiDetails.policies.some(x => {
            return x.policyType === 1 && x.policyName === 'HCA-Api-Key-Proxy-Protection'
          })
        }
        return false
      }
    },
    isOAuth2: {
      get: function () {
        if (this.apiDetails && this.apiDetails.policies) {
          return this.apiDetails.policies.some(x => {
            return x.policyType === 1 && x.policyName === 'HCA-OAuth2-Proxy-Protection-Shared-Flow'
          })
        }
        return false
      }
    },
    apiSecurityPolicy: {
      get: function () {
        return {
          isApiKey: this.isApiKey,
          isPingJwt: this.isPingJwt,
          isOAuth2: this.isOAuth2,
          isLoading: this.isLoading
        }
      }
    }
  },
  mounted: function () {
    if (this.$route.params.id) {
      this.apiId = this.$route.params.id
      this.getEnvironments()
      this.getApiInfo().then(() => {
        this.$store.commit('breadcrumbs', [
          {
            text: 'Apis',
            exact: true,
            disabled: false,
            to: '/apis'
          },
          {
            text: this.api.displayName,
            exact: true,
            disabled: false,
            to: `/apis/details/${this.apiId}`
          }
        ])
      })

      this.getProducts()
      this.getSubscribedProducts()
      this.getDeployments()
    } else {
      alert('could not find api as an ID was not provided in the url')
    }
  }
}
</script>

<style scoped>

</style>
