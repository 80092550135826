<template>
  <v-container>
    <div v-if="api.displayName">
    <v-row id="v-step-7">
      <v-col cols="12" md="4">
        <v-select label="Select Version" @change="getSwaggerJson" :items="api.availableVersions" v-model="version" />
      </v-col>
      <v-col cols="12" md="4" v-if="version">
        <v-select @click:prepend="setLatestEnvironment" prepend-icon="mdi-refresh" :items="filteredAvailableEnvironments" label="Select Environment" v-model="environment" item-text="environment.displayName" item-value="environment.id" no-data-text="This API hasn't been deployed to any environments"/>
      </v-col>
      <v-col cols="12" md="4" v-if="!apiSecurityPolicy.isPingJwt">
        <v-select label="Select Subscribed Product" :items="filteredSubscribedProducts" v-model="apiConsoleProduct" item-text="productName" return-object no-data-text="No products were found with a subscription to the selected environment. Use the subscription box above to subscribe."/>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-alert
          v-if="filteredAvailableEnvironments.length == 0"
          type="info"
          elevation="2"
          border="top"
          colored-border
        >
          This API has not yet been deployed to any environments. Please try selecting a different version or contacting the <a @click="routeToProduct(api.productId)">Api Product Team</a>.
        </v-alert>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" v-if="swaggerJson && !loadingSwagger && !hideSwagger">
        <v-alert
          v-if="!apiConsoleProduct"
          type="info"
          elevation="2"
          border="top"
          colored-border
        >
          Please select a product from the dropdown above prior to executing any API calls below or downloading the postman collection
        </v-alert>
        <div  class="info-box">
        <div class="pl-3">
          <span class="item-header">Url: </span>
          <a :href="setSchema(swaggerJson) + swaggerJson.host + swaggerJson.basePath">{{setSchema(swaggerJson)}}{{ swaggerJson.host }}{{ swaggerJson.basePath }}</a>
          <v-btn v-clipboard:copy="setSchema(swaggerJson) + swaggerJson.host + swaggerJson.basePath" v-clipboard:success="copyMouseClick" icon>
              <v-icon >mdi-clipboard-text-play-outline</v-icon>
            </v-btn>
        </div>
        <div class="pl-3">
          <span class="item-header">Description:</span>
          <br/>
          <div v-html="compiledMarkdown(swaggerJson.info.description)"></div>
        </div>
        <div class="pl-3" v-if="apiProduct && apiProduct.affiliates && affiliates !== null">
          <span class="item-header">Affiliates:</span>
          <br/>
          <v-chip
            class="ma-1"
            v-for="(item, index) in apiAffiliates" :key="index"

          >
            {{ item }}
          </v-chip>
        </div>
        <div class="pl-3">
          <span class="item-header">Downloads:</span>
          <br />
          <v-btn class="ma-3" @click="downloadPostmanCollection" :disabled="!apiConsoleProduct && !apiSecurityPolicy.isPingJwt">
            <v-img style="max-height: 25px; max-width: 25px; margin-right: 5px;" left src="@/assets/postman.png" />  Postman Collection
          </v-btn>
          <v-btn class="ma-3" @click="downloadSwaggerJson">
            <v-img style="max-height: 25px; max-width: 25px; margin-right: 5px;" left src="@/assets/swagger.jpg" />  Swagger Json
          </v-btn>
        </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" :style="loadingSwagger || hideSwagger ? 'display: none' : 'display: block'">
        <div id="swagger-ui" :class="$vuetify.theme.dark ? 'swagger-ui-dark' : 'swagger-ui-light'"></div>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="loadingSwagger" align="center" justify="center">
        <v-progress-circular
          class="ma-4"
          :size="125"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
    </div>
    <div v-else align="center" justify="center">
      <v-progress-circular
      class="ma-4"
      :size="125"
      color="primary"
      indeterminate
    ></v-progress-circular>
    </div>
    <v-dialog
    v-model="postmanDialog"
    width="950"
    >
      <v-card>
        <v-card-title
          :class="$vuetify.theme.dark ? 'headline grey darken-3' : 'headline grey lighten-3'"
          primary-title
        >
          Your download is starting
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <h3>View the instructions below to import the collection into postman:</h3>
              </v-col>
              <v-carousel cycle hide-delimiters>
                <v-carousel-item
                  src="@/assets/PostmanSteps/PostmanStep1.png"
                ></v-carousel-item>
                <v-carousel-item
                  src="@/assets/PostmanSteps/PostmanStep2.png"
                ></v-carousel-item>
                <v-carousel-item
                  src="@/assets/PostmanSteps/PostmanStep3.png"
                ></v-carousel-item>
                <v-carousel-item
                  src="@/assets/PostmanSteps/PostmanStep5.png"
                ></v-carousel-item>
                <v-carousel-item
                  src="@/assets/PostmanSteps/PostmanStep6.png"
                ></v-carousel-item>
                <v-carousel-item
                  src="@/assets/PostmanSteps/PostmanStep7.png"
                ></v-carousel-item>
              </v-carousel>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="postmanDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import SwaggerUIBundle from 'swagger-ui'
import 'swagger-ui/dist/swagger-ui.css'
import axios from 'axios'
import marked from 'marked'
import { mapState } from 'vuex'

export default {
  name: 'api-console-component',
  props: {
    subscribedProducts: {
      required: true
    },
    apiId: {
      required: true
    },
    api: {
      required: true
    },
    selectedVersion: {
      required: true
    },
    environments: {
      required: true
    },
    deployments: {
      required: true
    },
    apiSecurityPolicy: {
      required: true
    }
  },
  computed: {
    ...mapState(['oidcStore']),
    version: {
      get: function () {
        return this.selectedVersion
      },
      set: function (value) {
        this.$emit('updateVersion', value)
      }
    },
    environmentsForProduct: function () {
      if (this.apiConsoleProduct) {
        let duplicateProducts = this.filteredSubscribedProducts.filter(x => {
          return x.productName === this.apiConsoleProduct.productName
        })
        return duplicateProducts.map(x => {
          return x
        })
      }
      return [ ]
    },
    filteredSubscribedProducts: function () {
      if (this.subscribedProducts) {
        return this.subscribedProducts.filter(x => {
          return ((x.subscriptionStatus === 'Approved' || x.subscriptionStatus === 0) && x.environmentId === this.environment)
        })
      }
      return [ ]
    },
    filteredAvailableEnvironments: function () {
      if (this.deployments && this.deployments.environments) {
        return this.deployments.environments.filter(x => {
          return x.currentDeployments.filter(y => {
            return ((y.status === 'Approved' || y.status === 0) && y.version === this.version)
          }).length > 0
        })
      }
      return [ ]
    },
    apiAffiliates: function () {
      let affiliates = [ ]
      if (this.affiliates && this.apiProduct && this.apiProduct.affiliates) {
        this.apiProduct.affiliates.forEach(item => {
          var affil = this.affiliates.filter(x => x.id === item)

          if (affil && affil[0]) {
            affiliates.push(affil[0].displayName)
          }
        })
      }
      return affiliates
    }
  },
  watch: {
    version: function () {
      this.setLatestEnvironment()
    },
    environment: function () {
      this.apiConsoleProduct = null
      this.getSwaggerJson()
    },
    api: function () {
      this.getProductDetails()
      this.getAffiliates()
      this.setLatestEnvironment()
    },
    deployments: function () {
      this.setLatestEnvironment()
    }
  },
  data: () => ({
    apiConsoleProduct: null,
    swaggerJson: null,
    postmanDialog: false,
    apiToken: null,
    loadingSwagger: false,
    hideSwagger: true,
    environment: null,
    securityPolicy: null,
    oidc_access_token: null,
    deployedEnvironments: [ ]
  }),
  methods: {
    compiledMarkdown: function (input) {
      return marked(input, { sanitize: true })
    },
    copyMouseClick () {
      this.$store.commit('triggerSnack', { snackMessage: 'Url copied to clipboard', snackColor: 'success' })
    },
    getAccessToken: function () {
      if (!this.apiConsoleProduct) {
        let error = {
          response: {
            data: 'Please select a product before trying out the API'
          }
        }
        this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false })
        return false
      }

      if (!this.environment) {
        let error = {
          response: {
            data: 'Please select an environment before trying out the API'
          }
        }
        this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false })
        return false
      }

      return axios(
        `${process.env.VUE_APP_BASEURL}/product/${this.apiConsoleProduct.productId}/environments/${this.environment}/token`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.apiToken = response.data
            return response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getAccessToken() })
          })
    },
    getSwaggerJson: function () {
      if (this.environment) {
        this.loadingSwagger = true

        return axios(
          `${process.env.VUE_APP_BASEURL}/api/${this.apiId}/versions/${this.version}/environments/${this.environment}/openapi`,
          {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
          })
          .then(
            (response) => {
              // Set values to be available within SwaggerUIBundle scope
              this.loadingSwagger = false
              this.hideSwagger = false
              this.swaggerJson = response.data
              this.securityPolicy = this.apiSecurityPolicy
              this.oidc_access_token = this.securityPolicy.isPingJwt ? this.oidcStore.access_token : null

              // Build the open API spec
              const ui = SwaggerUIBundle({
                spec: this.swaggerJson,
                dom_id: '#swagger-ui',
                requestInterceptor: async x => {
                  if (!this.apiConsoleProduct && !this.securityPolicy.isPingJwt) {
                    let error = {
                      response: {
                        data: 'Please select a product before trying out the API'
                      }
                    }
                    this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false })
                    return false
                  }

                  if (!this.securityPolicy.isPingJwt) {
                    // sets this.apiToken
                    await this.getAccessToken()
                  }

                  if (this.securityPolicy.isPingJwt) {
                    x.headers['Authorization'] = `Bearer ${this.oidc_access_token}`
                  } else if (this.securityPolicy.isOAuth2) {
                    x.headers['Authorization'] = `Bearer ${this.apiToken.access_token}`
                  } else if (this.securityPolicy.isApiKey) {
                    x.headers['Authorization'] = `Bearer ${this.apiToken.client_id}`
                  }
                  return x
                },
                presets: [
                  SwaggerUIBundle.presets.apis,
                  SwaggerUIBundle.SwaggerUIStandalonePreset
                ],
                plugins: [
                  SwaggerUIBundle.plugins.DownloadUrl
                ],
                layout: 'BaseLayout'
              })
            },
            (error) => {
              this.loadingSwagger = false
              this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getSwaggerJson() })
            })
      }
    },
    downloadPostmanCollection: function () {
      this.postmanDialog = true
      axios(
        `${process.env.VUE_APP_BASEURL}/api/${this.apiId}/version/${this.version}/environment/${this.environment}/postman?productId=${this.apiConsoleProduct.productId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          responseType: 'blob' // important
        })
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${this.api.displayName}.zip`)
            document.body.appendChild(link)
            link.click()
          },
          (error) => {
            error.response.data.text().then(text => {
              var json = JSON.parse(text)
              this.$store.commit('showErrorDialog', {
                errorData: {
                  response: {
                    data: json
                  }
                },
                persistErrorDialog: false,
                retryMethod: () => this.downloadPostmanCollection()
              })
              return error
            })
          })
    },
    downloadSwaggerJson: function () {
      axios(
        `${process.env.VUE_APP_BASEURL}/api/${this.apiId}/versions/${this.version}/environments/${this.environment}/openapi`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          responseType: 'blob' // important
        })
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${this.api.displayName}.json`)
            document.body.appendChild(link)
            link.click()
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.downloadSwaggerJson() })
            return error
          })
    },
    setLatestEnvironment: function () {
      if (this.version && this.deployments && this.deployments.environments) {
        var environment = this.deployments.environments.filter(x => {
          return x.currentDeployments.filter(y => {
            return ((y.status === 'Approved' || y.status === 0) && y.version === this.version)
          }).length > 0
        })

        if (environment && environment[0]) {
          this.environment = environment[0].environment.id
        }
      }
    },
    getProductDetails: function () {
      return axios(
        `${process.env.VUE_APP_BASEURL}/product/${this.api.productId}?publicView=true`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.apiProduct = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getAffiliates() })
            return error
          })
    },
    getAffiliates: function () {
      return axios(
        `${process.env.VUE_APP_BASEURL}/affiliates/`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.affiliates = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getAffiliates() })
            return error
          })
    },
    routeToProduct: function (productid) {
      this.$router.push(`/product-view/${productid}/`)
    },

    setSchema (data) {
      let schema = 'http'

      if (data && data.schemes && data.schemes[0]) {
        schema = data.schemes[0]
      }

      return schema + '://'
    }
  },
  mounted: function () {
    // this.setLatestEnvironment()
  }
}
</script>

<style>
.selected {
  background-color:#D9D9D6 !important
}

.verb-selection {
  width: 105px;
  text-align: center;
}

.auth-wrapper {
  display: none !important;
}
.information-container {
  display: none;
}

.item-header {
  font-weight: bold;
}

.subscription-environment {
  margin: 2px;
}

.scheme-container {
  display: none !important;
}

.info-box {
  border: 1px solid rgba(0, 0, 0, 0.4);
  padding: 10px;
}

.swagger-ui-dark *:not(input):not(.content-type):not(textarea) {
  color: white !important;
}

.swagger-ui-dark .opblock-section-header {
  background-color: #424242 !important;
}

.swagger-ui-dark .model-container{
  background-color: #424242 !important;
}

.v-application code {
    background-color: #333!important;
    color: #fff!important;
}
</style>
