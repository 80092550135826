<template>
  <v-card
    outlined
    class="mx-auto"
    min-height="325px"
    id="v-step-2" v-if="apiSecurityPolicy.isLoading">
  <v-skeleton-loader
    ref="skeleton"
    type="card-avatar: list-item-avatar">
  </v-skeleton-loader>
  </v-card>
  <v-card v-else
    outlined
    class="mx-auto"
    min-height="325px"
    id="v-step-2">
    <v-list-item three-line>
      <v-list-item-content>
        <div class="overline mb-4">Subscription</div>
        <v-card-text v-if="!apiSecurityPolicy.isLoading && apiSecurityPolicy.isPingJwt">
          <div id="jwt-security">This API utilizes Ping JWT tokens and doesn't require a subscription in this portal.</div>
        </v-card-text>
        <v-card-text v-else>
            <v-row id="v-step-3">
              <v-select :items="products" label="Select Your Product" v-model="product" item-text="displayName" item-value="id" />
            </v-row>
            <v-row id="v-step-4">
              <v-select :items="filteredAvailableEnvironments" label="Select Environment" v-model="environment" item-text="environment.displayName" item-value="environment.id" no-data-text="This API hasn't been deployed to any environments"/>
            </v-row>
            <v-row class="d-flex justify-end" id="v-step-5">
              <v-btn @click="subscribeToApi()">Subscribe</v-btn>
            </v-row>
        </v-card-text>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
import axios from 'axios'

export default {
  name: 'subscription-component',
  props: {
    products: {
      required: true
    },
    deployments: {
      required: true
    },
    apiId: {
      required: true
    },
    apiSecurityPolicy: {
      required: true
    }
  },
  computed: {
    filteredAvailableEnvironments: function () {
      if (this.deployments.environments) {
        return this.deployments.environments.filter(x => {
          return x.currentDeployments.filter(y => {
            return ((y.status === 'Approved' || y.status === 0))
          }).length > 0
        })
      }
      return [ ]
    }
  },
  data: () => ({
    product: '',
    environment: ''
  }),
  methods: {
    subscribeToApi: function () {
      axios(
        `${process.env.VUE_APP_BASEURL}/subscriptions/${this.product}/environments/${this.environment}/apis/${this.apiId}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            if (response.data.approved) {
              this.$store.commit('triggerSnack', { snackMessage: 'The subscription request has been created successfully', snackColor: 'success' })
            } else {
              this.$store.commit('triggerSnack', { snackMessage: 'The subscription request has been submitted and is pending approval', snackColor: 'warning' })
            }
            this.$emit('subscriptionSuccessful')
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.subscribeToApi() })
          })
    }
  }
}
</script>

<style scoped>

</style>
