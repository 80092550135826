<template>
  <v-container>
    <v-row>
      <v-col cols="12" v-if="groupedProductSubscriptions">
        <v-row align="center" justify="start">
          <v-card
              class="ma-2 pa-1"
              hover
              ripple
              v-for="(product, index) in groupedProductSubscriptions" :key="index"
              max-width="350"
              min-width="350"
              :color="$vuetify.theme.dark ? 'grey darken-4' : ''"
              @click="routeToProductByName(index)"
          >
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="headline api-name">{{ index }}</v-list-item-title>
                <v-list-item-subtitle class="d-flex flex-wrap">
                  <template v-for="subscription in product">
                    <v-tooltip bottom :key="subscription.subscriptionId">
                      <template v-slot:activator="{ on }">
                        <v-chip
                          dark
                          :color="subscription.subscriptionStatus === 'Approved' || subscription.subscriptionStatus === 0 ? '#4CAF50' : '#FF9800'"
                          v-on="on"
                          class="subscription-environment"
                        >
                          {{ subscription.environmentName}}
                        </v-chip>
                      </template>
                      <span>{{ subscription.subscriptionStatus === 'Approved' || subscription.subscriptionStatus === 0 ? 'Subscription Approved' : 'Subscription Pending' }}</span>
                    </v-tooltip>
                    </template>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-card-actions>
              <v-btn text @click.stop="selectedUnsubscribeProduct = { name: index, subscription: product }; unsubscriptionDialog = true">Unsubscribe</v-btn>
            </v-card-actions>
          </v-card>
        </v-row>
      </v-col>
      <v-col cols="12" v-if="subscribedProducts.length === 0">
        <p>No Subscriptions found for this api.</p>
      </v-col>
    </v-row>
    <v-dialog
    v-model="unsubscriptionDialog"
    width="500"
    >
      <v-card>
        <v-card-title
          :class="$vuetify.theme.dark ? 'headline grey darken-3' : 'headline grey lighten-3'"
          primary-title
        >
          Unsubscribe from {{ selectedUnsubscribeProduct.name }}
        </v-card-title>
        <v-card-text>
          Select an environment to unsubscribe:
          <v-select :items="selectedUnsubscribeProduct.subscription" v-model="selectedUnsubscribeSubscriptionId" item-text="environmentName" item-value="subscriptionId" />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="revokeSubscriptionRequest"
          >
            Unsubscribe
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'subscription-products-component',
  props: {
    subscribedProducts: {
      required: true
    },

    api: {
      required: true
    }
  },
  computed: {
    groupedProductSubscriptions: function () {
      return this.subscribedProducts.reduce((acc, obj) => {
        let key = obj['productName']
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push(obj)
        return acc
      }, {})
    }
  },
  data: () => ({
    unsubscriptionDialog: false,
    selectedUnsubscribeProduct: { },
    selectedUnsubscribeSubscriptionId: null
  }),
  methods: {
    routeToProductByName: function (productName) {
      var product = this.subscribedProducts.filter(x => {
        return x.productName === productName
      })

      this.$router.push(`/product-view/${product[0].productId}`)
    },
    revokeSubscriptionRequest: function () {
      axios(
        `${process.env.VUE_APP_BASEURL}/subscriptions/${this.selectedUnsubscribeSubscriptionId}/revoke`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.$emit('unsubscribeSuccessful')
            this.selectedUnsubscribeSubscriptionId = null
            this.selectedUnsubscribeProduct = { }
            this.unsubscriptionDialog = false
            this.$store.commit('triggerSnack', { snackMessage: 'The subscription has been unsubscribed successfully', snackColor: 'success' })
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.revokeSubscriptionRequest() })
          })
    }
  }
}
</script>

<style scoped>

</style>
