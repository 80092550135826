<template>
  <v-card
    class="mx-auto"
    outlined
    min-height="325px"
    id="v-step-1"
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="overline mb-4">Details</div>
        <v-card-text>
          <p><span class="item-header">Owning Product:</span> <a @click="routeToProduct(api.productId)">{{ api.productName }}</a></p>
          <p><span class="item-header">Published:</span> {{ getDate(api.createdDateTimeUtc) }}</p>
          <p><span class="item-header">Supported Versions:</span> {{ api.availableVersions.join(', ') }}</p>
          <p><span class="item-header">Environments Requiring Approval:</span> {{ environmentsRequiringApproval }}</p>
          <p v-if="api.tags"><span class="item-header">Tags:</span></p>
          <p v-if="api.tags"><v-chip
              v-for="(tag, tagIndex) in api.tags"
              :key="tagIndex"
              class="ma-1 pa-3"
            >
              {{tag}}
            </v-chip>
          </p>
        </v-card-text>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
import moment from 'moment'

export default {
  name: 'api-overview-component',
  props: {
    api: {
      required: true
    },
    apiDetails: {
      required: true
    },
    environments: {
      required: true
    }
  },
  data: () => ({
  }),
  computed: {
    environmentsRequiringApproval: function () {
      if (this.apiDetails && this.apiDetails.environmentsRequiringApproval && this.environments) {
        var environments = this.environments.filter(x => {
          return this.apiDetails.environmentsRequiringApproval.includes(x.id)
        })
        return environments.map(x => {
          return x.displayName
        }).join(', ')
      }
      return null
    }
  },
  methods: {
    routeToProduct: function (productid) {
      this.$router.push(`/product-view/${productid}/`)
    },
    getDate: function (date) {
      return moment(date).fromNow()
    }
  }
}
</script>

<style scoped>

</style>
